<form class="card m-auto" name="form" #f="ngForm" novalidate>
    <div class="card-body">
        <h4 class="mb-3 text-center">{{ "auth.login" | translate }}</h4>
        <ng-container *ngIf="!isCodeFormShown; else elseTemplate">
            <div class="form-group col-12">
                <label class="d-block mb-2">{{"global.phone_number" | translate}}</label>
          
                <ngx-intl-tel-input [cssClass]="'form-control'" [enableAutoCountrySelect]="true"
                    [enablePlaceholder]="true" [searchCountryFlag]="true" [searchCountryField]="[
                SearchCountryField.Iso2,
                SearchCountryField.Name
              ]" [selectedCountryISO]="CountryISO.Egypt" [phoneValidation]="true" name="phone" [(ngModel)]="phone"
                    #phoneInput="ngModel" [ngClass]="{ 'is-invalid': f.submitted && phoneInput.invalid }" required>
                </ngx-intl-tel-input>
            </div>

            <div id="recaptcha-container-1" class="col-12 my-3" required></div>

            <div class="col-12 d-flex flex-column">
                <button type="button" class="btn primary" [disabled]="f.form.invalid || !isRecaptchaVerified"
                    (click)="sendVerificationCode()">
                    {{ "global.send_code" | translate }}
                </button>

                <button type="button" (click)="signUpWithGoogle()" class="btn border mt-5 mb-3">
                    <i class="fa-brands fa-google mx-2"></i>{{ "global.sign_google" | translate }}
                </button>

                <button type="button" (click)="signUpWithFacebook()" class="btn border">
                    <i class="fa-brands fa-facebook-f mx-2"></i>{{ "global.sign_facebook" | translate }}
                </button>
            </div>
        </ng-container>
        <ng-template #elseTemplate>
            <div>
                <div>
                    <label for="code">{{"global.auth_confirm_description" | translate}}</label><br />
                    <input type="text" name="code" class="form-control" [(ngModel)]="verificationCode" />
                    <button (click)="verifyLoginCode()" class="mt-4 btn primary w-100">
                        {{ "global.verify" | translate }}
                    </button>

                    <button class="btn link px-0" (click)="editPhone()">
                        <small> {{ "global.edit_phone_number" | translate }}</small>
                    </button>
                </div>
            </div>
        </ng-template>
    </div>
</form>