import { AngularFireAuth } from '@angular/fire/compat/auth';
import { ErrorService } from './../error/error.service';
import { AuthenticationService } from 'src/app/core/services/authentication.service';
import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { NgxSpinnerService } from 'ngx-spinner';
@Injectable({
  providedIn: 'root',
})
export class ApiInterceptor implements HttpInterceptor {
  needAuth: string[] = [
    'patients/family-members',
    'lite-companies',
    'auth/check-patient',
    'bookings/',
    'patients/profile',
    'users/settings',
    'patients/contact',
  ];

  constructor(
    private auth: AuthenticationService,
    private spinner: NgxSpinnerService,
    private errorService: ErrorService,
    private afAuth: AngularFireAuth
  ) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> | any {
    this.spinner.show();

    if (this.needAuth.find((l) => l === request.url.split('api/')[1])) {
      if (this.auth.isAuthenticated()) {
        request = request.clone({
          setHeaders: {
            Authorization: `Token ${this.auth.token}`,
          },
        });
        return this.handleNext(request, next);
      } else {
        this.afAuth.idToken.subscribe(
          (token) => {
            request = request.clone({
              setHeaders: {
                Authorization: `Token ${token}`,
              },
            });
            return this.handleNext(request, next);
          },
          (error) => {
            this.errorService.showError(error.message);
            return of(null);
          }
        );
      }
    } else {
      return this.handleNext(request, next);
    }
  }

  handleNext(request: any, next: any) {
    return next.handle(request).pipe(
      tap(
        (event: HttpEvent<any>) => {},
        (err: any) => {
          this.handleError(err);
        },
        () => {
          this.spinner.hide();
        }
      )
    );
  }

  handleError(err: any) {
    this.spinner.hide();
    let userMessage = 'An unexpected error occurred. Please try again later.';
  
    if (err instanceof HttpErrorResponse) {
      switch (err.status) {
        case 400:
          userMessage = 'Oops! Something is incorrect. Please check and try again.';
          break;
        case 401:
          userMessage = 'You need to sign in to continue.';
          break;
        case 403:
          userMessage = 'You do not have permission to perform this action.';
          break;
        case 404:
          userMessage = 'We couldn’t find what you were looking for.';
          break;
        case 500:
          userMessage = 'Sorry, something went wrong on our side. Please try again later.';
          break;
        default:
          userMessage = err.error?.message || userMessage;
      }
    }
  
    this.errorService.showError(userMessage);
  }
}
