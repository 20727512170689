import { ToastService } from './toast.service';
import { Patient } from 'src/app/core/models/patient';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import {
  ActionCodeSettings,
  browserLocalPersistence,
  browserSessionPersistence,
  EmailAuthProvider,
  FacebookAuthProvider,
  GithubAuthProvider,
  GoogleAuthProvider,
  inMemoryPersistence,
  Persistence,
  TwitterAuthProvider,
} from 'firebase/auth';
import { User } from '@firebase/auth-types';
import { UserCredential } from '@firebase/auth-types';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { Subject } from 'rxjs';
import { isPlatformBrowser } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  showModal$ = new Subject<boolean>();
  isBrowser = false;

  constructor(
    private afAuth: AngularFireAuth,
    private toastr: ToastService,
    @Inject(PLATFORM_ID) platformId: any
  ) {
    this.isBrowser = isPlatformBrowser(platformId);

    this.onAuthStateChanged();
    this.onIdTokenChanged();
  }

  public get providerId(): any {
    return {
      Email: EmailAuthProvider.PROVIDER_ID,
      Google: GoogleAuthProvider.PROVIDER_ID,
      GitHub: GithubAuthProvider.PROVIDER_ID,
      Twitter: TwitterAuthProvider.PROVIDER_ID,
      Facebook: FacebookAuthProvider.PROVIDER_ID,
    };
  }

  set showModal(s: boolean) {
    this.showModal$.next(s);
  }

  public get persistence(): any {
    return {
      LOCAL: browserLocalPersistence,
      SESSION: browserSessionPersistence,
      None: inMemoryPersistence,
    };
  }

  public get currentUser(): User | null {
    if (this.isBrowser) {
      const localUser = localStorage.getItem('user');
      return JSON.parse(localUser as string);
    }
    return null;
  }

  public get userClaims(): any {
    if (this.isBrowser) {
      return JSON.parse(localStorage.getItem('claims') || '');
    }
  }

  private onIdTokenChanged(): void {
    this.afAuth.onIdTokenChanged(
      (user) => {
        if (user) {
          this.setUserClaims(user);
        } else {
        }
      },
      (error) => {
        this.toastr.error(error.message);
      }
    );
  }

  private onAuthStateChanged(): void {
    this.afAuth.onAuthStateChanged((user) => {
      if (user) {
        if (this.isBrowser) {
          localStorage.setItem('user', JSON.stringify(user));
        }
      } else {
        this.signOut();
      }
    });
  }

  private setUserClaims(user: User): void {
    user.getIdTokenResult().then((idTokenResult) => {
      if (this.isBrowser) {
        localStorage.setItem('expirationTime', idTokenResult.expirationTime);
        localStorage.setItem('token', idTokenResult.token);
        localStorage.setItem('claims', JSON.stringify(idTokenResult.claims));
      }
    });
  }

  getUserStatus(): Promise<User> {
    return new Promise<User>((resolve, reject) => {
      if (this.currentUser) {
        resolve(this.currentUser);
      }
      this.afAuth.onAuthStateChanged((user) => {
        if (user) {
          resolve(user);
        } else {
          reject(Error('user null'));
        }
      });
    });
  }

  setPersistence(persistence: Persistence): void {
    this.afAuth.setPersistence(persistence.type);
  }

  signInWithGithubAuthProvider(): Promise<UserCredential> {
    return this.afAuth.signInWithPopup(new GithubAuthProvider());
  }

  signInWithGoogleAuthProvider(): Promise<UserCredential> {
    return this.afAuth.signInWithPopup(new GoogleAuthProvider());
  }

  signInWithTwitterAuthProvider(): Promise<UserCredential> {
    return this.afAuth.signInWithPopup(new TwitterAuthProvider());
  }

  signInWithFacebookAuthProvider(): Promise<UserCredential> {
    return this.afAuth.signInWithPopup(new FacebookAuthProvider());
  }

  signInWithEmailAndPassword(
    email: string,
    password: string
  ): Promise<UserCredential> {
    return this.afAuth.signInWithEmailAndPassword(email, password);
  }

  signInAnonymously(): Promise<UserCredential> {
    return this.afAuth.signInAnonymously();
  }

  signInWithEmailLink(
    email: string,
    emailLink: string
  ): Promise<UserCredential> {
    return this.afAuth.signInWithEmailLink(email, emailLink);
  }

  loginPhone(phone: string, appVerifier: any) {
    return this.afAuth.signInWithPhoneNumber(phone, appVerifier);
  }

  signInWithFirebase(firebaseToken: string) {
     return this.afAuth.signInWithCustomToken(firebaseToken)
  }

  isSignInWithEmailLink(): Promise<boolean> | null {
    if (this.isBrowser) {
      return this.afAuth.isSignInWithEmailLink(window.location.href);
    }
    return null;
  }

  linkWithGithubAuthProvider(): Promise<UserCredential> | undefined {
    return this.currentUser?.linkWithPopup(new GithubAuthProvider());
  }
  unLinkGithubAuthProvider(): Promise<User> | undefined {
    return this.currentUser?.unlink(new GithubAuthProvider().providerId);
  }

  linkWithGoogleAuthProvider(): Promise<UserCredential> | undefined {
    return this.currentUser?.linkWithPopup(new GoogleAuthProvider());
  }

  unLinkGoogleAuthProvider(): Promise<User> | undefined {
    return this.currentUser?.unlink(new GoogleAuthProvider().providerId);
  }

  linkWithTwitterAuthProvider(): Promise<UserCredential> | undefined {
    return this.currentUser?.linkWithPopup(new TwitterAuthProvider());
  }

  unLinkTwitterAuthProvider(): Promise<User> | undefined {
    return this.currentUser?.unlink(new TwitterAuthProvider().providerId);
  }

  linkWithFacebookAuthProvider(): Promise<UserCredential> | undefined {
    return this.currentUser?.linkWithPopup(new FacebookAuthProvider());
  }

  unLinkFacebookAuthProvider(): Promise<User> | undefined {
    return this.currentUser?.unlink(new FacebookAuthProvider().providerId);
  }

  verifySignInMethodForEmail(
    email: string,
    providerId: string
  ): Promise<{ signin: boolean; methods: string[] }> {
    return new Promise<{ signin: boolean; methods: string[] }>(
      (resolve, reject) => {
        this.fetchSignInMethodsForEmail(email)
          .then((list) => {
            if (list.includes(providerId)) {
              resolve({ signin: true, methods: list });
            } else {
              resolve({ signin: false, methods: list });
            }
          })
          .catch((reason) => reject(reason));
      }
    );
  }

  fetchSignInMethodsForEmail(email: string): Promise<string[]> {
    return this.afAuth.fetchSignInMethodsForEmail(email);
  }

  createUserWithEmailAndPassword(
    email: string,
    password: string
  ): Promise<UserCredential> {
    return this.afAuth.createUserWithEmailAndPassword(email, password);
  }

  updatePassword(pass: string): Promise<void> | undefined {
    return this.currentUser?.updatePassword(pass);
  }

  sendPasswordResetEmail(
    email: string,
    actionCodeSettings?: ActionCodeSettings
  ): Promise<void> {
    return this.afAuth.sendPasswordResetEmail(email, actionCodeSettings);
  }

  signOut(): Promise<void> {
    return this.afAuth.signOut().then(() => {
      if (this.isBrowser) {
        localStorage.clear();
      }
    });
  }

  get token(): string | null {
    if (this.isBrowser) {
      const token = localStorage.getItem('token');
      if (token) {
        return token;
      } else {
        this.onAuthStateChanged();
      }
    }
    return null;
  }

  isAuthenticated(): boolean | null {
    if (this.isBrowser) {
      const expiresAt = localStorage.getItem('expirationTime');
      if (expiresAt) {
        return new Date().getTime() < new Date(expiresAt).getTime();
      }
    }
    return null;
  }

  set patient(patient: Patient | null) {
    if (this.isBrowser && patient) {
      patient.init();
      localStorage.setItem('patient', JSON.stringify(patient));
    }
  }

  get patient(): Patient | null {
    if (this.isBrowser) {
      const patient = localStorage.getItem('patient');
      if (patient) {
        return Object.assign(new Patient(), JSON.parse(patient));
      }
    }
    return null;
  }
}
