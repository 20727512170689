import { ContactRequest } from 'src/app/core/models/contactRequest';
import { Injectable } from '@angular/core';
import { ApiBaseService } from './api-base.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ErrorService } from '../error/error.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HomepageContent } from 'src/app/core/models/cms/homepageContent';
import { ContentPageSearchParams } from 'src/app/core/models/cms/contentPageSearchParams';
import { ContentPageSearchResult } from 'src/app/core/models/cms/contentPageSearchResults';
import { ContentPage } from 'src/app/core/models/cms/contentPage';
import { BranchSearchResult } from 'src/app/core/models/providers/providerSearchResults';

@Injectable({
  providedIn: 'root',
})
export class ApiService extends ApiBaseService {
  base_url = environment.backend_host + '/api';

  constructor(
    public http: HttpClient,
    public override errorService: ErrorService,
    protected override spinner: NgxSpinnerService
  ) {
    super(spinner, errorService);
  }

  // CMS
  public getHomepageContent = (): Observable<HomepageContent> =>
    this.http
      .get<any>(`${this.base_url}/cms/homepage`)
      .pipe(map(this.deserializeAs(HomepageContent)));

  public getContentPages(
    searchParams: ContentPageSearchParams
  ): Observable<ContentPageSearchResult> {
    const p = this.serializeParams(searchParams, new HttpParams());
    return this.http
      .get<any>(`${this.base_url}/cms/content-pages`, { params: p })
      .pipe(map(this.deserializeAs(ContentPageSearchResult)));
  }

  public getContentPageBySlug = (slug: string): Observable<ContentPage> =>
    this.http
      .get<any>(`${this.base_url}/cms/content-pages/${slug}`)
      .pipe(map(this.deserializeAs(ContentPage)));

  
  requestOtp(phoneNumber: string): Observable<any> {
        return this.http.post(`${this.base_url}/auth/request-otp`, { phone_number: phoneNumber });
  }
    
  validateOtp(phoneNumber: string, otp: string): Observable<any> {
        return this.http.post(`${this.base_url}/auth/validate-otp`, { phone_number: phoneNumber, otp });
  }
  public getBlogs(
    searchParams: ContentPageSearchParams
  ): Observable<ContentPageSearchResult> {
    const p = this.serializeParams(searchParams, new HttpParams());
    return this.http
      .get<any>(`${this.base_url}/cms/blogs`, { params: p })
      .pipe(map(this.deserializeAs(ContentPageSearchResult)));
  }

  // Branches
  public getBranches(providerId: number|null): Observable<BranchSearchResult> {
    return this.http
      .get<any>(`${this.base_url}/providers/${providerId}/branches`)
      .pipe(map(this.deserializeAs(BranchSearchResult)));
  }

  contactUs(contactRequest: ContactRequest): Observable<ContactRequest> {
    return this.http
      .post<any>(`${this.base_url}/contact-us/`, this.serialize(contactRequest));
  }
}
