<nav class="container navbar-expand-lg fixed-top px-0" id="navbar">
  <div class="py-2 px-3 shadow rounded mx-3 mx-lg-auto navbar-container">
    <div class="d-flex justify-content-between w-100">
      <a class="nav-item navbar-brand d-flex pl-0" [routerLink]="['/' | localize]">
        <img src="/assets/images/medoc-logo.svg" loading="lazy" lazyLoad="true" ngOptimizedImage alt="MEDOC Logo"
          width="40" height="40" class="logo" />
      </a>
      <div class="collapse navbar-collapse " [class.show]="isMobileMenuShown" id="navbarToggler">
        <div class="d-flex align-items-center justify-content-between w-100 p-3 d-lg-none">
          <a class="nav-item navbar-brand d-flex pl-0" [routerLink]="['/' | localize]">
            <img src="/assets/images/medoc-logo.svg" loading="lazy" lazyLoad="true" ngOptimizedImage alt="MEDOC Logo"
              width="40" height="40" class="logo" />
          </a>

          <button class="navbar-toggler px-0" type="button" data-toggle="collapse" data-target="#navbarToggler"
            aria-controls="navbarToggler" aria-expanded="false" aria-label="Toggle navigation"
            (click)="toggleMobileMenu(!isMobileMenuShown)">
            <app-icon *ngIf="isMobileMenuShown" icon="close" size="32px"></app-icon>
          </button>
        </div>
        <ul class="navbar-nav d-flex align-items-center w-100">
          <li class="nav-item close-item">
            <a class="nav-link" [routerLink]="['/doctors' | localize]">
              {{ "global.book_appointment" | translate }}</a>
          </li>
          <li class="nav-item px-0" ngbDropdown>
            <a class="nav-link text-bold" (click)="$event.preventDefault()" href="javascript:void(0)" tabindex="0"
              data-toggle="solutionsDropdown" ngbDropdownToggle id="solutionsDropdown" role="button">
              {{ "global.solutions" | translate }}
            </a>
            <div ngbDropdownMenu class="dropdown-menu" aria-labelledby="solutionsDropdown">
              <a ngbDropdownItem routerLink="solution/business" class="py-3 close-item">
                {{ "nav.for_businesses" | translate}}
              </a>
              <a ngbDropdownItem
                [routerLink]="isEnglish ? 'solution/clinic-management-system' : 'solution/نظام-إدارة-العيادات'"
                class="py-3 close-item">
                {{ "nav.for_providers" | translate}}
              </a>
              <a ngbDropdownItem routerLink="for-patients" class="py-3 close-item">
                {{ "nav.for_patients" | translate}}
              </a>
            </div>

          </li>
          <li class="nav-item">
            <a class="nav-link close-item" [routerLink]="['/blog' | localize]">{{
              "nav.blog" | translate}}</a>
          </li>
          <li class="nav-item">
            <a class="nav-link close-item" [routerLink]="['/pages/events' | localize]">{{
              "nav.events" | translate}}</a>
          </li>
          <li class="nav-item close-item">
            <a class="nav-link" [routerLink]="['/contact-us' | localize]">{{
              "nav.contact" | translate}}</a>
          </li>

          <li class="nav-item ml-auto">
            <button type="button" class="btn text-primary font-weight-bold " (click)="switchLanguage()">
              {{ "nav.language" | translate }}
            </button>
          </li>
          <li class="nav-item px-0" *ngIf="!user">
            <button class=" btn btn-primary btn-sm" (click)="login()">
              {{ "auth.login" | translate }}
            </button>
          </li>
          <li class="nav-item px-0" *ngIf="user" ngbDropdown>
            <a class="nav-link d-flex align-items-center justify-content-center user-dropdown lrt px-0"
              data-toggle="dropdown" href="javascript:void(0)" ngbDropdownToggle>
              <div class="user-img mx-2">
                <img width="40" height="40" alt="personal image"
                  [src]=" patient && patient.profileImg ? patient.profileImg : 'assets/images/default-avatar.webp' " />
              </div>
              <span class="rtl">{{ "global.hi" | translate }},
                {{ patient?.name || user.displayName }}</span>
            </a>
            <div ngbDropdownMenu>
              <a routerLink="profile" ngbDropdownItem class="py-3">
                <i class="fa-regular fa-user mr-2"></i>
                {{ "global.profile" | translate }}
              </a>
              <button ngbDropdownItem (click)="signOut()" class="py-3">
                <i class="fa-solid fa-arrow-right-from-bracket mr-2"></i>
                {{ "global.log_out" | translate }}
              </button>
            </div>
          </li>
        </ul>
      </div>

      <button class="navbar-toggler px-0" type="button" data-toggle="collapse" data-target="#navbarToggler"
        aria-controls="navbarToggler" aria-expanded="false" aria-label="Toggle navigation"
        (click)="toggleMobileMenu(!isMobileMenuShown)">
        <app-icon *ngIf="!isMobileMenuShown" icon="hamburger" size="32px"></app-icon>
        <app-icon *ngIf="isMobileMenuShown" icon="close" size="32px"></app-icon>
      </button>
    </div>
  </div>
</nav>