import { Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FirebaseError } from 'firebase/app';
import { RecaptchaVerifier, getAuth } from 'firebase/auth';
import { CountryISO, SearchCountryField } from 'ngx-intl-tel-input';
import { NgxSpinnerService } from 'ngx-spinner';
import { ApiService } from 'src/app/core/services/api/api.service';
import { AuthenticationService } from 'src/app/core/services/authentication.service';
import { ToastService } from 'src/app/core/services/toast.service';
import { WindowService } from 'src/app/core/window.service';

@Component({
  selector: 'app-form-login',
  templateUrl: './form-login.component.html',
  styleUrls: ['./form-login.component.scss']
})
export class FormLoginComponent implements OnInit, OnDestroy {
  @Output('onLogin') onLogin: EventEmitter<void> = new EventEmitter();
  @ViewChild('authModal') authModal: any;
  phone: any | null = null;
  password = '';

  windowRef: any;

  verificationCode: string | null = null;
  appVerifier: RecaptchaVerifier | null = null;
  isRecaptchaVerified = false;

  user: any;
  isCodeFormShown = false;

  separateDialCode = false;
  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;

  constructor(
    private authService: AuthenticationService,
    private win: WindowService,
    private toastr: ToastService,
    private spinner: NgxSpinnerService,
    private apiService: ApiService
  ) {

  }

  ngOnDestroy(): void {
    this.phone = null;
    this.verificationCode = null;
    this.isCodeFormShown = false;
  }

  ngOnInit(): void {
    this.handleRecaptchaVerifier();
  }

  sendVerificationCode() {
    if (this.windowRef) {
        this.spinner.show();
        this.appVerifier = this.windowRef?.recaptchaVerifier;
      if (this.appVerifier) {
        this.apiService.requestOtp(this.phone.e164Number).subscribe({
          next: () => {
            this.isCodeFormShown = true;
            this.spinner.hide();
          },
          error: (err) => {
            this.toastr.error('An error occurred: ' + err.message);
          },
        });
      }
    }
  }

  verifyLoginCode() {
    this.spinner.show();
    if (this.verificationCode) {
      this.apiService.validateOtp(this.phone.e164Number, this.verificationCode).subscribe({
        next: (response) => {
          const firebaseToken = response.token;
          this.authService.signInWithFirebase(firebaseToken).then((userCredential) => {
            this.afterLogin();
          })
          .catch((error) => {
            console.error("Error signing in:", error);
          });
        },
        error: (err) => {
          this.spinner.hide();
          this.toastr.error(err + ', Incorrect code entered?');
        },
      });
    }
  }

  signUpWithGoogle(): void {
    this.spinner.show();
    this.authService.signInWithGoogleAuthProvider().then(
      () => {
        this.afterLogin();
      },
      (error: FirebaseError) => {
        this.spinner.hide();
        this.toastr.error(error.message);
      }
    );
  }

  signUpWithFacebook(): void {
    this.spinner.show();
    this.authService.signInWithFacebookAuthProvider().then(
      () => {
        this.afterLogin();
      },
      (error: FirebaseError) => {
        this.spinner.hide();
        this.toastr.error(error.message);
      }
    );
  }

  afterLogin() {
    this.isCodeFormShown = false;
    this.spinner.hide();
    this.onLogin.emit();
  }

  editPhone() {
    this.handleRecaptchaVerifier();
    this.isCodeFormShown = false;
  }

  handleRecaptchaVerifier() {
    this.isRecaptchaVerified = false;
    setTimeout(() => {
      this.windowRef = this.win.windowRef;
      this.windowRef.recaptchaVerifier = new RecaptchaVerifier(
        'recaptcha-container-1',
        {
          size: 'normal',
          callback: (response: any) => {
            this.isRecaptchaVerified = true;
          },
          'expired-callback': () => {
            this.isRecaptchaVerified = false;
          },
        },
        getAuth()
      );
      this.windowRef.recaptchaVerifier?.render();
      this.windowRef.recaptchaVerifier?.verify().then((r: any) => {
        this.isRecaptchaVerified = true;
      });
    }, 100);
  }
 
  
}
